// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults
$body-color: #fff;
$body-bg: #fff;
$border-radius: .4rem;
$success: #7952b3;

$link-color: white;
$link-decoration: underline;
$link-hover-color:                        darken($link-color, 15%) !default;
$link-hover-decoration:                   underline !default;



@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

@import "bootstrap/scss/root"; // Required
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group"; // Requires forms
// @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar"; // Requires nav
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";


//
// Custom styles
//

@import "icon-list";

body {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


// Style Bootstrap icons
.bi {
  fill: currentColor;
}


@keyframes colorPulse {
  0% {
    color: lighten(green, 70%);
  }
  40% {
    color: green;
  }
  50% {
    color: darken(green, 5%);
  }
  100% {
    color: darken(green, 20%);
  }
}


.wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  width: 100vw;
}

.column {
  & > * {
    display: block;
    animation: 3s infinite colorPulse;
    padding: 0 0.125em;
  }
}
  @for $colNum from 1 through 80 {
    $duration: random(100) / 23 + 1;
    $delay:  $duration / 50;
 
    @for $i from 1 through 50 {
      .col-#{$colNum} :nth-child(#{$i}) {
        animation-duration: #{$duration}s;
        animation-delay: #{$i * $delay}s;
      }
    }
  }

  #skills {
    background-color: #333546;
  }

  .svg path {
    fill: #fff;
  }

#logo img {
  height: 80px;
  width: auto;
}

.pager {
  position: relative;
  top: -20px;
}


@include media-breakpoint-down (sm) {
  h1 {font-size: 2rem;}

}

$rfs-factor: 2;
$rfs-two-dimensional: true;